import React from "react";
import "../styles/404.scss";
import PageTransition from "gatsby-plugin-page-transitions";
import { Link } from "gatsby";

export default function FourOhFour() {
  return (
    <PageTransition>
      <div className="bg"></div>
      <div className="404_title">
        <h1>404</h1>
        <Link href="/">home</Link>
      </div>
    </PageTransition>
  );
}
